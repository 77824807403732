/* Change this file to get your personal Porfolio */

// Website related settings
const settings = {
  isSplash: false, // Change this to false if you don't want Splash screen.
};

//SEO Related settings
const seo = {
  title: "Marah's Portfolio",
  description:
    "Purpose-driven Engineer with a balance of interpersonal and technical skills.",
  og: {
    title: "Marah Shahin Portfolio",
    type: "website",
    url: "http://marahshahin.com/",
  },
};

//Home Page
const greeting = {
  title: "Marah Shahin",
  logo_name: "MarahShahin",
  nickname: "",
  subTitle:
    "Purpose-driven Engineer with a balance of interpersonal and technical skills.",
  resumeLink:
    "https://github.com/msh181/Resume/blob/main/Marah_Shahin_Resume.pdf",
  portfolio_repository: "/projects", //ignore this - i redirected the "star me on github button"
  githubProfile: "https://github.com/msh181",
};

const socialMediaLinks = [
  /* Your Social Media Link */
  // github: "https://github.com/ashutosh1919",
  // linkedin: "https://www.linkedin.com/in/marahshahin/",
  // gmail: "marahshahin12@gmail.com",
  // gitlab: "https://gitlab.com/ashutoshhathidara98",
  // facebook: "https://www.facebook.com/laymanbrother.19/",
  // twitter: "https://twitter.com/ashutosh_1919",
  // instagram: "https://www.instagram.com/layman_brother/"

  {
    name: "Github",
    link: "https://github.com/msh181",
    fontAwesomeIcon: "fa-github", // Reference https://fontawesome.com/icons/github?style=brands
    backgroundColor: "#181717", // Reference https://simpleicons.org/?q=github
  },
  {
    name: "LinkedIn",
    link: "https://www.linkedin.com/in/marahshahin/",
    fontAwesomeIcon: "fa-linkedin-in", // Reference https://fontawesome.com/icons/linkedin-in?style=brands
    backgroundColor: "#0077B5", // Reference https://simpleicons.org/?q=linkedin
  },
/*   {
    name: "YouTube",
    link: "https://youtube.com/c/DevSense19",
    fontAwesomeIcon: "fa-youtube", // Reference https://fontawesome.com/icons/youtube?style=brands
    backgroundColor: "#FF0000", // Reference https://simpleicons.org/?q=youtube
  }, */
  {
    name: "Gmail",
    link: "mailto:marahshahin12@gmail.com",
    fontAwesomeIcon: "fa-google", // Reference https://fontawesome.com/icons/google?style=brands
    backgroundColor: "#D14836", // Reference https://simpleicons.org/?q=gmail
  },
/*  {
    name: "X-Twitter",
    link: "https://twitter.com/ashutosh_1919",
    fontAwesomeIcon: "fa-x-twitter", // Reference https://fontawesome.com/icons/x-twitter?f=brands&s=solid
    backgroundColor: "#000000", // Reference https://simpleicons.org/?q=x
  },
  {
    name: "Facebook",
    link: "https://www.facebook.com/ShahinMarah/",
    fontAwesomeIcon: "fa-facebook-f", // Reference https://fontawesome.com/icons/facebook-f?style=brands
    backgroundColor: "#1877F2", // Reference https://simpleicons.org/?q=facebook
  },
  {
    name: "Instagram",
    link: "https://www.instagram.com/marah_1/",
    fontAwesomeIcon: "fa-instagram", // Reference https://fontawesome.com/icons/instagram?style=brands
    backgroundColor: "#E4405F", // Reference https://simpleicons.org/?q=instagram
  }, */
];

const skills = {
  data: [
    {
      title: "Data Science & AI",
      fileName: "DataScienceImg",
      skills: [
        "Developing highly scalable production ready models for various deep learning and statistical use cases.",
        "Experience in implementing MLOps and Generative AI in a variety of large companies.",
        "Complex quantitative modelling for dynamic forecasting and time series analysis.",
        "Trusted AI/ML blog and thought leadership author.",
      ],
      softwareSkills: [
        {
          skillName: "Tensorflow",
          fontAwesomeClassname: "logos-tensorflow",
          style: {
            backgroundColor: "transparent",
          },
        },
        {
          skillName: "Deeplearning",
          imageSrc: "deeplearning_ai_logo.png",
        },
        {
          skillName: "AWS",
          fontAwesomeClassname: "logos:aws",
          style: {
            backgroundColor: "transparent",
            color: "#3776AB",
          },
        },
        {
          skillName: "Python",
          fontAwesomeClassname: "ion-logo-python",
          style: {
            backgroundColor: "transparent",
            color: "#3776AB",
          },
        },
        {
          skillName: "Keras",
          fontAwesomeClassname: "simple-icons:keras",
          style: {
            backgroundColor: "white",
            color: "#D00000",
          },
        },
        {
          skillName: "PyTorch",
          fontAwesomeClassname: "logos-pytorch",
          style: {
            backgroundColor: "transparent",
          },
        },
        {
          skillName: "NVIDIA",
          fontAwesomeClassname: "cib:nvidia",
          style: {
            backgroundColor: "transparent",
            color: "#76B900",
          },
        },
      ],
    },
    {
      title: "Stakeholder Relations",
      fileName: "FullStackImg",
      skills: [
        "Excels in strategic stakeholder engagement through leading client meetings, projects, and workshops.",
        "Marah leverages her communication prowess to present complex concepts, aligning academic insights with practical applications.",
        "Recognised by her seniors for her exceptional verbal and written communication skills, Marah excels in delivering impactful presentations and reports, skillfully tailoring communication to various audiences.",
        "In her role as a volunteer manager for two large recurring events, Marah exhibits strong leadership in project management, fostering collaboration and addressing diverse stakeholder needs.",
      ],
      softwareSkills: [
        {
          skillName: "Presentations",
          fontAwesomeClassname: "mdi:presentation",
          style: {
            color: "#E34F26",
          },
        },
        {
          skillName: "Reports",
          fontAwesomeClassname: "tabler:report",
          style: {
            color: "#1572B6",
          },
        },
        {
          skillName: "Project Management",
          fontAwesomeClassname: "fluent-mdl2:workforce-management",
          style: {
            color: "#CC6699",
          },
        },
        {
          skillName: "Communication",
          fontAwesomeClassname: "icon-park-outline:communication",
        },
      ],
    },
    {
      title: "Cloud, Data, & Development",
      fileName: "CloudInfraImg",
      skills: [
        "Experience working on multiple cloud platforms, data analysis, visualisation, software development, and data engineering.",
        "Successfully navigated the complexities of cloud platforms, leveraging them to optimise data engineering processes for efficient and scalable solutions.",
        "Hands-on experience in data engineering - reflected in Marah's role, where she has demonstrated the ability to design, implement, and manage robust data architectures to support the evolving needs of projects.",
      ],
      softwareSkills: [
        {
          skillName: "C++",
          fontAwesomeClassname: "teenyicons:cplusplus-outline",
          style: {
            color: "#CB3837",
          },
        },
        {
          skillName: "JavaScript",
          fontAwesomeClassname: "simple-icons:javascript",
          style: {
            backgroundColor: "#000000",
            color: "#F7DF1E",
          },
        },
        {
          skillName: "Python",
          fontAwesomeClassname: "ion-logo-python",
          style: {
            backgroundColor: "transparent",
            color: "#3776AB",
          },
        },
        {
          skillName: "Pandas",
          fontAwesomeClassname: "logos:pandas-icon",
        },
        {
          skillName: "MATLAB",
          fontAwesomeClassname: "devicon:matlab",
        },
        {
          skillName: "R",
          fontAwesomeClassname: "devicon:r",
        },
        {
          skillName: "SQL",
          fontAwesomeClassname: "devicon:sqldeveloper",
        },
        {
          skillName: "PowerBI",
          fontAwesomeClassname: "simple-icons:powerbi",
          style: {
            color: "#CB3837",
          },
        },
        {
          skillName: "Tableau",
          fontAwesomeClassname: "logos:tableau-icon",
        },

        {
          skillName: "ReactJS",
          fontAwesomeClassname: "simple-icons:react",
          style: {
            color: "#61DAFB",
          },
        },
        {
          skillName: "NodeJS",
          fontAwesomeClassname: "logos:nodejs-icon",
          style: {
            color: "#339933",
          },
        },
        {
          skillName: "NPM",
          fontAwesomeClassname: "simple-icons:npm",
          style: {
            color: "#CB3837",
          },
        },
        {
          skillName: "AWS",
          fontAwesomeClassname: "logos:aws",
          style: {
            backgroundColor: "transparent",
            color: "#3776AB",
          },
        },
        {
          skillName: "Azure",
          fontAwesomeClassname: "devicon:azure",
        },
        {
          skillName: "GCP",
          fontAwesomeClassname: "devicon:googlecloud",
        },
      ],
    },
    /* {
      title: "UI/UX Design",
      fileName: "DesignImg",
      skills: [
        "⚡ Designing highly attractive user interface for mobile and web applications",
        "⚡ Customizing logo designs and building logos from scratch",
        "⚡ Creating the flow of application functionalities to optimize user experience",
      ],
      softwareSkills: [
        {
          skillName: "Adobe XD",
          fontAwesomeClassname: "simple-icons:adobexd",
          style: {
            color: "#FF2BC2",
          },
        },
        {
          skillName: "Figma",
          fontAwesomeClassname: "simple-icons:figma",
          style: {
            color: "#F24E1E",
          },
        },
        {
          skillName: "Adobe Illustrator",
          fontAwesomeClassname: "simple-icons:adobeillustrator",
          style: {
            color: "#FF7C00",
          },
        },
        {
          skillName: "Inkscape",
          fontAwesomeClassname: "simple-icons:inkscape",
          style: {
            color: "#000000",
          },
        },
      ],
    }, */
  ],
};

// Education Page
const competitiveSites = {
  competitiveSites: [
    {
      siteName: "LeetCode",
      iconifyClassname: "simple-icons:leetcode",
      style: {
        color: "#F79F1B",
      },
      profileLink: "https://leetcode.com/layman_brother/",
    },
    {
      siteName: "HackerRank",
      iconifyClassname: "simple-icons:hackerrank",
      style: {
        color: "#2EC866",
      },
      profileLink: "https://www.hackerrank.com/layman_brother",
    },
    {
      siteName: "Codechef",
      iconifyClassname: "simple-icons:codechef",
      style: {
        color: "#5B4638",
      },
      profileLink: "https://www.codechef.com/users/ashutosh_1919",
    },
    {
      siteName: "Codeforces",
      iconifyClassname: "simple-icons:codeforces",
      style: {
        color: "#1F8ACB",
      },
      profileLink: "http://codeforces.com/profile/layman_brother",
    },
    {
      siteName: "Hackerearth",
      iconifyClassname: "simple-icons:hackerearth",
      style: {
        color: "#323754",
      },
      profileLink: "https://www.hackerearth.com/@ashutosh391",
    },
    {
      siteName: "Kaggle",
      iconifyClassname: "simple-icons:kaggle",
      style: {
        color: "#20BEFF",
      },
      profileLink: "https://www.kaggle.com/laymanbrother",
    },
  ],
};

const degrees = {
  degrees: [
    {
      title: "University of Auckland",
      subtitle: "Bachelor of Engineering (First Class Honours) - Engineering Science",
      logo_path: "uoa.png",
      alt_name: "UoA",
      //duration: "2017 - 2021",
      descriptions: [
        "Dean's Honours List (achieved by those attaining a GPA of over 8.25/9)",
        "Honours Thesis title: Optimal Experimental Design for Understanding Burn Injuries, A+",
        "Additional research project completed related to mathematical modelling",
        "Completed courses related to Machine Learning / AI, Optimisation / Operations Research, Mathematical Modelling, Fluid Mechanics, etc",
        "Clubs: Engineers Without Borders (Exec), Women in Engineering",
      ],
      website_link: "https://www.auckland.ac.nz/en/study/study-options/find-a-study-option/engineering-science.html",
    },
    {
      title: "University of Auckland",
      subtitle: "Bachelor of Commerce - Double Major in Finance, and Innovation & Entrepreneurship",
      logo_path: "uoa.png",
      alt_name: "UoA",
      //duration: "2018 - 2021",
      descriptions: [
        "Completed a research project related to Innovation & Entrepreneurship",
        "First in Course Award",
        "Clubs: Management Consulting Club",
      ],
      website_link: "https://www.auckland.ac.nz/en/study/study-options/find-a-study-option/bachelor-of-commerce-bcom.html",
    },
    {
      title: "Cambridge International Examinations",
      subtitle: "Avondale College",
      logo_path: "cie.png",
      alt_name: "CIE",
      //duration: "2014 - 2016",
      descriptions: [
        "Completed IGCSE, AS, and A Levels",
        "Subjects included: Maths, Physics, Business Studies, Accounting, and Psychology",
      ],
      website_link: "https://www.cambridgeinternational.org/",
    },
  ],
};

//#1F70C199 (blue aws)
// #00000099 (grey deeplearning)
// #0C9D5899 (green nvidia)

const certifications = {
  certifications: [
    {
      title: "AWS Certified Machine Learning - Specialty",
      subtitle: "- Amazon Web Services",
      logo_path: "awsML.png",
      certificate_link:
        "https://www.credly.com/badges/d9c9e297-5304-4399-9db6-d26c31cb8b70/public_url",
      alt_name: "AWS",
      color_code: "transparent",
    },
    {
      title: "AWS Certified Solutions Architect - Associate",
      subtitle: "- Amazon Web Services",
      logo_path: "awsSAA.png",
      certificate_link:
        "https://www.credly.com/badges/41bf6c12-8c4e-44f1-b273-7671ac54f0bb/public_url",
      alt_name: "AWS",
      color_code: "transparent",
    },
    {
      title: "AWS Certified Cloud Practitioner",
      subtitle: "- Amazon Web Services",
      logo_path: "awsccp.png",
      certificate_link:
        "https://www.credly.com/badges/9bb3aa15-37da-41a1-86e9-a1a460c85c2f/public_url",
      alt_name: "AWS",
      color_code: "transparent",
    },
    {
      title: "AWS Partner: Generative AI Essentials (Business)",
      subtitle: "- Amazon Web Services",
      logo_path: "awsGAI.png",
      certificate_link:
        "https://www.credly.com/badges/353c9134-2cc9-4057-857f-5d663a9741da/public_url",
      alt_name: "AWS",
      color_code: "transparent",
    },
    {
      title: "How Diffusion Models Work",
      subtitle: "- Andrew Ng (deeplearning.ai)",
      logo_path: "deeplearning_ai_logo.png",
      certificate_link:
        "https://www.deeplearning.ai/short-courses/how-diffusion-models-work/",
      alt_name: "deeplearning.ai",
      color_code: "transparent",
    },
    {
      title: "LangChain for LLM Application Development",
      subtitle: "- Andrew Ng & Jacob Lee (deeplearning.ai)",
      logo_path: "deeplearning_ai_logo.png",
      certificate_link:
        "https://www.deeplearning.ai/short-courses/build-llm-apps-with-langchain-js/",
      alt_name: "deeplearning.ai",
      color_code: "transparent",
    },
    {
      title: "GSI NVIDIA Technologies (2023)",
      subtitle: "- NVIDIA Partner Network",
      logo_path: "nvidia2.png",
      certificate_link:
        "https://www.nvidia.com/en-us/about-nvidia/partners/",
      alt_name: "NVIDIA",
      color_code: "transparent",
    },
    {
      title: "Deep Learning",
      subtitle: "- NVIDIA Deep Learning Institute (in progress)",
      logo_path: "nvidia2.png",
      certificate_link:
        "https://courses.nvidia.com/courses/course-v1:DLI+S-FX-01+V1/",
      alt_name: "NVIDIA",
      color_code: "transparent",
    },
    {
      title: "Image Segmentation",
      subtitle: "- NVIDIA Deep Learning Institute",
      logo_path: "nvidia2.png",
      certificate_link:
        "https://courses.nvidia.com/courses/course-v1:DLI+L-FX-04+V2/",
      alt_name: "NVIDIA",
      color_code: "transparent",
    },
    {
      title: "Modeling Time Series Data with Recurrent Neural Networks in Keras",
      subtitle: "- NVIDIA Deep Learning Institute (in progress)",
      logo_path: "nvidia2.png",
      certificate_link:
        "https://courses.nvidia.com/courses/course-v1:DLI+L-FX-24+V1/",
      alt_name: "NVIDIA",
      color_code: "transparent",
    },
    {
      title: "Dataiku ML Practitioner",
      subtitle: "- Dataiku",
      logo_path: "Dataiku.png",
      certificate_link:
        "https://verify.skilljar.com/c/ihqikiyxrhdv",
      alt_name: "Dataiku",
      color_code: "transparent",
    },
    {
      title: "Dataiku Core Designer",
      subtitle: "- Dataiku",
      logo_path: "Dataiku.png",
      certificate_link:
        "https://verify.skilljar.com/c/heauh6m68n8x",
      alt_name: "Dataiku",
      color_code: "transparent",
    },
/*     {
      title: "Advanced ML on GCP",
      subtitle: "- GCP Training",
      logo_path: "google_logo.png",
      certificate_link:
        "https://www.coursera.org/account/accomplishments/verify/5JZZM7TNQ2AV",
      alt_name: "Google",
      color_code: "#0C9D5899",
    },
    {
      title: "DL on Tensorflow",
      subtitle: "- Laurence Moroney",
      logo_path: "deeplearning_ai_logo.png",
      certificate_link:
        "https://www.coursera.org/account/accomplishments/verify/6T4DCUGNK8J8",
      alt_name: "deeplearning.ai",
      color_code: "#00000099",
    },
    {
      title: "Fullstack Development",
      subtitle: "- Jogesh Muppala",
      logo_path: "coursera_logo.png",
      certificate_link:
        "https://www.coursera.org/account/accomplishments/certificate/NRANJA66Y2YA",
      alt_name: "Coursera",
      color_code: "#2A73CC",
    },
    {
      title: "Kuberenetes on GCP",
      subtitle: "- Qwiklabs",
      logo_path: "gcp_logo.png",
      certificate_link:
        "https://google.qwiklabs.com/public_profiles/e4d5a92b-faf6-4679-a70b-a9047c0cd750",
      alt_name: "GCP",
      color_code: "#4285F499",
    },
    {
      title: "Cryptography",
      subtitle: "- Saurabh Mukhopadhyay",
      logo_path: "nptel_logo.png",
      certificate_link:
        "https://drive.google.com/open?id=1z5ExD_QJVdU0slLkp8CBqSF3-C3g-ro_",
      alt_name: "NPTEL",
      color_code: "#FFBB0099",
    },
    {
      title: "Cloud Architecture",
      subtitle: "- Qwiklabs",
      logo_path: "gcp_logo.png",
      certificate_link:
        "https://google.qwiklabs.com/public_profiles/5fab4b2d-be6f-408c-8dcb-6d3b58ecb4a2",
      alt_name: "GCP",
      color_code: "#4285F499",
    }, */
  ],
};

// Experience Page
const experience = {
  title: "Experience",
  subtitle: "Work, Internship, and Volunteering",
  description:
    "",
  header_image_path: "experience.svg",
  sections: [
    {
      title: "Work",
      work: true,
      experiences: [
        {
          title: "Artificial Intelligence & Data Specialist",
          company: "Deloitte - Full time",
          company_url: "https://www2.deloitte.com/nz/en/services/consulting.html",
          logo_path: "dtt.png",
          duration: "Feb 2022 - Mar 2024",
          location: "Auckland, New Zealand",
          description: [
            "Specialising in Machine Learning.",
            "Undertaken and/or led a range of projects including machine learning, data engineering, cloud engineering, generative AI, mathematical modelling, data governance, data visualisation (Power BI, ServiceNow), AWS, ethical AI, data analysis, and data strategy.",
            "Specialist since June 2023 (Analyst prior to this).",
            ],
          color: "#0879bf",
        },
        {
          title: "Machine Learning Engineer",
          company: "TLD Engineers - Contractor",
          company_url: "https://tlde.co.nz/",
          logo_path: "tld.png",
          duration: "May 2022 - Ongoing",
          location: "Auckland, New Zealand",
          description: [
            "Project valued at over $45m where 97% of Wellington roads require speed limit revision under the newly established: Land Transport Rule: Setting of Speed Limits 2022. Responsibilities include: Development Communications and Engagement Plan that follows best practice framework, Derived and proposed a mathematical model for prioritising road projects in New Zealand, Client & Stakeholder management and development of on-going timeline programme via Microsoft Project. Currently Marah is working on a Machine Learning engagement to support resourcing for traffic projects.",
          ],
          color: "#9b1578",
        },
        {
          title: "Business Analyst",
          company: "Hilti - Fixed term contract",
          company_url: "https://www.hilti.co.nz/",
          logo_path: "hilti.png",
          duration: "Aug 2021 - Jan 2022",
          location: "Auckland, New Zealand",
          description: [
            "Project lead redefining customer prioritisation by developing an optimisation programme to mathematically identify the highest potential long-term customers. Facilitated numerous workshops and training sessions for various teams socialising the newly implemented data management standards.",
          ],
            color: "#fc1f20",
        },
        {
          title: "Research Assistant - Engineering Science",
          company: "University of Auckland - Fixed term contract",
          company_url: "https://www.auckland.ac.nz/en/study/study-options/find-a-study-option/engineering-science.html",
          logo_path: "uoa.png",
          duration: "Nov 2020 - Apr 2021",
          location: "Auckland, New Zealand",
          description: [
            "Supported the development of ENGSCI 314 (Mathematical Modelling 3) where the following topics are covered: Probability, Data Analysis, Ordinary Differential Equations, and Partial Differential Equations.",
          ],
          color: "#fc1f20",
        },
        {
          title: "Research Assistant - Innovation and Entrepreneurship",
          company: "University of Auckland - Fixed term contract",
          company_url: "https://www.auckland.ac.nz/en/study/study-options/find-a-study-option/bachelor-of-commerce-bcom.html",
          logo_path: "uoa.png",
          duration: "Nov 2021 - Apr 2022",
          location: "Auckland, New Zealand",
          description: [
            "Completed a comprehensive research project focused on exploring the entrepreneurial ecosystem in Taiwan through a case study investigating Gogoro.",
          ],
          color: "#fc1f20",
        },
        {
          title: "Teaching Assistant - Engineering",
          company: "University of Auckland - Fixed term contract",
          company_url: "https://www.auckland.ac.nz/en/study/study-options/find-a-study-option/engineering-science.html",
          logo_path: "uoa.png",
          duration: "Mar 2020 - Jul 2020",
          location: "Auckland, New Zealand",
          description: [
            "Supported assignment and test marking for ENGGEN 140 Energy and Society",
          ],
          color: "#fc1f20",
        },
        {
          title: "Blogger - Engineering Science",
          company: "University of Auckland - Fixed term contract",
          company_url: "https://student-editorials.blogs.auckland.ac.nz/category/engineering-science/page/4/",
          logo_path: "uoa.png",
          duration: "Jun 2018 - Oct 2018",
          location: "Auckland, New Zealand",
          description: [
            "Blogger for the Engineering Science Department describing my experience in second year.",
          ],
          color: "#fc1f20",
        },
      ],
    },
    {
      title: "Internships",
      experiences: [
        {
          title: "Program Advisory Engineer",
          company: "Aurecon",
          company_url: "https://www.tiktok.com/en/",
          logo_path: "aurecon.png",
          duration: "Jan 2021 - Mar 2021",
          location: "Auckland, New Zealand",
          description: [
            "Involved in many engineering projects related to: financial modelling, rapid build housing schemes, architecture & design, legal exposure, and child psychology.",
          ],
          color: "#000000",
        },
        {
          title: "Asset Management Engineer",
          company: "WSP",
          company_url: "https://www.delhivery.com/",
          logo_path: "wsp.jpeg",
          duration: "Nov 2018 - Nov 2019",
          location: "Auckland, New Zealand",
          description: [
            "Project lead analysing footpath profiles to better identify trips and faults with the goal of reducing hazards and improving footpath comfort for users on wheels. Assisted in general road engineering projects",
          ],
            
          color: "#ee3c26",
        },
      ],
    },
    {
      title: "Volunteering",
      experiences: [
        {
          title: "Finance Director",
          company: "New Zealand Eid Day",
          company_url: "https://www.nzeidday.com/",
          logo_path: "nzeidday.jpeg",
          duration: "Feb 2021 - Ongoing",
          location: "New Zealand",
          description: [
            "Leading and coordinating entertainment, finance, and (internal and vendor) stalls for a large biannual event catering to over 12,000 attendees in Auckland. Fundraised over $10,000 in 3 days from the public to support the event. Additional responsibilities include: co-leading stalls owner & stakeholder management and coordination and Advisor to the Entertainment team.",
          ],
          color: "#4285F4",
        },
        {
          title: "Operations Manager",
          company: "Football for Freedom",
          company_url: "https://www.facebook.com/footballforfreedom/",
          logo_path: "f4f.jpeg",
          duration: "Dec 2019 - Ongoing",
          location: "Auckland, New Zealand",
          description: [
            "Annual non-for-profit football tournament and festival. Managing operations and logistics. Stakeholder & vendor management. Advisor to the Entertainment team.",
          ],
          color: "#D83B01",
        },
        {
          title: "President",
          company: "Engineers Without Borders",
          company_url: "https://www.ewb.org.nz/",
          logo_path: "ewb.png",
          duration: "Oct 2020 - Dec 2021",
          location: "Auckland, New Zealand",
          description: [
            "Led the Students Engineers Without Borders Chapter and completed the Pathways to Development Programme.",
          ],
          color: "#000000",
        },

      ],
    },
  ],
};

// Projects Page
const projectsHeader = {
  title: "Projects",
  description:
    "Marah is a life long student - she continues learning through personal and professional projects",
  avatar_image_path: "projects_image.svg",
};

const publicationsHeader = {
  title: "Publications",
  description:
    "I have worked on and published a few research papers and publications of my own.",
  avatar_image_path: "projects_image.svg",
};

const publications = {
  data: [
    {
      id: "MDEwOlJlcG9zaXRvcnkyNDU0NjcyNzQ=",
      name: "Artificial Intelligence Paper",
      createdAt: "2020-03-06T16:26:54Z",
      description: "Paper Written on Artificial Intelligence published in xyz ",
      url:
        "https://www.andrewng.org/publications/building-high-level-features-using-large-scale-unsupervised-learning/",
    },
    {
      id: "MDEwOlJlcG9zaXRvcnkyNDU0NjcyNzi=",
      name: "Artificial Intelligence Paper",
      createdAt: "2020-03-06T16:26:54Z",
      description: "Paper Written on Artificial Intelligence published in xyz ",
      url:
        "https://www.andrewng.org/publications/building-high-level-features-using-large-scale-unsupervised-learning/",
    },
    {
      id: "MDEwOlJlcG9zaXRvcnkyNDU0NjcyNze=",
      name: "Artificial Intelligence Paper",
      createdAt: "2020-03-06T16:26:54Z",
      description: "Paper Written on Artificial Intelligence published in xyz ",
      url:
        "https://www.andrewng.org/publications/building-high-level-features-using-large-scale-unsupervised-learning/",
    },
    {
      id: "MDEwOlJlcG9zaXRvcnkyNDU0NjcyNzt=",
      name: "Artificial Intelligence Paper",
      createdAt: "2020-03-06T16:26:54Z",
      description: "Paper Written on Artificial Intelligence published in xyz ",
      url:
        "https://www.andrewng.org/publications/building-high-level-features-using-large-scale-unsupervised-learning/",
    },
    {
      id: "MDEwOlJlcG9zaXRvcnkyNDU0NjcyNzb=",
      name: "Artificial Intelligence Paper",
      createdAt: "2020-03-06T16:26:54Z",
      description: "Paper Written on Artificial Intelligence published in xyz ",
      url:
        "https://www.andrewng.org/publications/building-high-level-features-using-large-scale-unsupervised-learning/",
    },
  ],
}; 

// Contact Page
const contactPageData = {
  contactSection: {
    title: "Let's Connect",
    profile_image_path: "marah3.jpg",
    description:
      "Feel free to reach Marah on LinkedIn or Gmail",
  },
/*    blogSection: {
    title: "Blogs",
    subtitle:
      "For individual fundamental empowerment, I like to write powerful lessons that create impact on each of the reader individually to change the core of their character.",
    link: "https://blogs.ashutoshhathidara.com/",
    avatar_image_path: "blogs_image.svg",
  }, 
  addressSection: {
    title: "Address",
    subtitle:
      "Ambavadi vas, Kanodar, T.A.-Palanpur, Dist.-Banaskantha, Gujarat - 385520",
    locality: "Kanodar",
    country: "IN",
    region: "Gujarat",
    postalCode: "385520",
    streetAddress: "Ambavadi vas",
    avatar_image_path: "address_image.svg",
    location_map_link: "https://goo.gl/maps/MpMqtRZytFchMkZ76",
  }, */
  blogSection: {
    title: "",
    subtitle:
      "",
    link: "",
    avatar_image_path: "",
  }, 
  addressSection: {
    title: "",
    subtitle:
      "",
    locality: "",
    country: "",
    region: "",
    postalCode: "",
    streetAddress: "",
    avatar_image_path: "",
    location_map_link: "",
  },
  phoneSection: {
    title: "",
    subtitle: "",
  },
};

const projects = {
  "projects" : [
      {
      "image" : "...",
      "title": "...",
      "bodyText": "...",
      "links": [
          {
              "text": "...",
              "href": "...",
          },
          {
              "text": "..",
              "href": "..",
          }
      ],
      "tags" : [
          "React",
          "Portfolio",
          "JavaScript",
        ],
      },
    ],
};


export {
  settings,
  seo,
  greeting,
  socialMediaLinks,
  skills,
  competitiveSites,
  degrees,
  certifications,
  experience,
  projectsHeader,
  publicationsHeader,
  publications,
  contactPageData,
  projects,
};
